<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-row dense>
      <v-col cols="12">
        <v-switch
          v-model="enabled"
          :label="$t('openingHours.enabled')"
          class="mt-0"
          hide-details
        />
      </v-col>
      <template v-if="enabled">
        <v-col cols="12">
          <v-switch
            v-model="useLocation"
            :label="$t('openingHours.useLocation')"
            class="mt-0"
            hide-details
          />
        </v-col>
        <v-col
          cols="12"
          v-if="false"
        >
          Opening rule
        </v-col>
        <v-col
          cols="12"
          v-if="false"
        >
          Weekday hours
        </v-col>
        <v-col
          cols="12"
          v-if="false"
        >
          Seasonal hours
        </v-col>
        <v-col
          cols="12"
          v-if="false"
        >
          Closed dates
        </v-col>
        <v-col
          cols="12"
          v-if="false"
        >
          Timezone
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
export default {
    name: 'OpeningHoursAdvanced',
    props: {
        openingHours: {
            type: Object,
            default: () => ({})
        }
    },
    emits: ['update:openingHours'],
    data() {
        return {
            openingHoursObject: {},
            enabled: false,
            useLocation: false,
            weekdayHours: [],
            seasonalHours: [],
            closedDates: [],
            timezone: null,
            openingRule: null
        }
    },
    mounted() {
        if(this.openingHours) {
            this.openingHoursObject = this.openingHours
            this.enabled = this.openingHours.enabled ?? false
            this.useLocation = this.openingHours.useLocation ?? false
            this.weekdayHours = this.openingHours.weekdayHours ?? []
            this.seasonalHours = this.openingHours.seasonalHours ?? []
            this.closedDates = this.openingHours.closedDates ?? []
            this.timezone = this.openingHours.timezone ?? null
            this.openingRule = this.openingHours.openingRule ?? null
        } else {
            this.openingHoursObject = {
                enabled: false,
                useLocation: false,
                weekdayHours: [],
                seasonalHours: [],
                closedDates: [],
                timezone: null,
                openingRule: null
            }
        }
    },
    watch: {
        enabled(value) {
            this.openingHoursObject.enabled = value
            this.$emit('update:openingHours', this.openingHoursObject)
        },
        useLocation(value) {
            this.openingHoursObject.useLocation = value
            this.$emit('update:openingHours', this.openingHoursObject)
        },
        weekdayHours(value) {
            this.openingHoursObject.weekdayHours = value
            this.$emit('update:openingHours', this.openingHoursObject)
        },
        seasonalHours(value) {
            this.openingHoursObject.seasonalHours = value
            this.$emit('update:openingHours', this.openingHoursObject)
        },
        closedDates(value) {
            this.openingHoursObject.closedDates = value
            this.$emit('update:openingHours', this.openingHoursObject)
        },
        timezone(value) {
            this.openingHoursObject.timezone = value
            this.$emit('update:openingHours', this.openingHoursObject)
        },
        openingRule(value) {
            this.openingHoursObject.openingRule = value
            this.$emit('update:openingHours', this.openingHoursObject)
        }
    }
}
</script>